import React, { useState, useContext, useRef } from "react"
import MainContext from "../stores/mainContext"
import { StaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import Nav from "../components/nav"
import Modal from "../components/modal"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper.scss"
import SwiperCore, { Pagination } from "swiper"
import { gsap } from "gsap"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import Popup from "../components/popup"

gsap.registerPlugin(ScrollToPlugin)

SwiperCore.use([Pagination])

const Header = () => {
  // Fix: https://github.com/gatsbyjs/gatsby/issues/26563
  // const data = useStaticQuery(graphql`
  //   query HeaderQuery {
  //     allDirectusAbout{
  //       nodes{
  //         about
  //         contact
  //         catalogues
  //         files {
  //           file_name
  //           file_iframe
  //          }
  //       }
  //     }
  //   }
  // `)
  // const contactContent = data.allDirectusAbout.nodes[0].contact
  // const aboutContent = data.allDirectusAbout.nodes[0].about
  // const cataloguesContent = data.allDirectusAbout.nodes[0].catalogues
  // const cataloguesFiles = data.allDirectusAbout.nodes[0].files

  const { mainContext, dispatch } = useContext(MainContext)
  const [showSearch, setShowSearch] = useState(false)
  const modalRef1 = useRef()
  const popupRef = useRef()
  const searchInput = useRef()
  const onShowSearch = () => {
    setShowSearch(!showSearch)
    if (!showSearch) {
      // TODO: refactor search component
      setTimeout(() => {
        searchInput.current.focus()
      }, 500)
    }
  }
  let searchClass = showSearch ? "search active" : "search"
  const handleClose = () => {
    dispatch({ type: "SET_SEARCH_TEXT", payload: "" })
    //setDisplay(false)
    // dispatch({ type: "STOP_VIDEO", payload: true });
  }

  return (
    <StaticQuery
      query={graphql`
          query HeaderQuery {
          allDirectusAbout{
            nodes{
              about
              contact
              files {
                file_name
                file_iframe
              }
            }
          }
        }
      `}
      render={data => (
        <header>
      <div
        className={`header-container ${mainContext.opened ? "modal-opened" : ""
          }`}
      >
        <div className={searchClass}>
          <button className="magnifier" type="button" onClick={onShowSearch}>
            0\
          </button>
          {showSearch ? (
            <>
              <input
                type="text"
                placeholder="Search..."
                onChange={ev => {
                  dispatch({
                    type: "SET_SEARCH_TEXT",
                    payload: ev.target.value,
                  })
                }}
                value={mainContext.searchText}
                ref={searchInput}
              />
              {mainContext.searchText.length > 0 && (
                <>
                  <span id="results">{mainContext.searchResultCount}</span>
                  <button className="close" onClick={handleClose}>
                    close
                  </button>
                </>
              )}
            </>
          ) : null}
        </div>
        <h1>
          <Link
            to="/"
            onClick={ev => {
              dispatch({ type: "SET_SEARCH_TEXT", payload: "" })
              dispatch({ type: "SET_CATEGORY", payload: null })
            }}
            // className={mainContext.clicked && !mainContext.category && "active"}
          >
            Groves Natcheva Architects
          </Link>
        </h1>
        <button
          className="btn btn-menu"
          onClick={() => {
            modalRef1.current.openModal()
            dispatch({ type: "SET_MODAL_OPENED", payload: true })
          }}
        >
          <span className="info-icon" role="img" aria-label="Info icon"></span>
        </button>
      </div>

      <Modal ref={modalRef1}>
        <div className="swiper-pagination"></div>
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          autoHeight={false}
          updateOnWindowResize={true}
          resizeObserver={true}
          onOrientationchange={swiper => setTimeout(() => swiper.update(), 250)}
          onResize={swiper => setTimeout(() => swiper.update(), 250)}
          pagination={{
            el: ".swiper-pagination",
            clickable: true,
            renderBullet: (index, className) => {
              const modalTabs = ["Contact", "About", "Catalogues"]
              return (
                '<span class="' +
                className +
                '">' +
                modalTabs[index] +
                "</span>"
              )
            },
          }}
          breakpoints={{
            880: {
              allowTouchMove: false,
              preventInteractionOnTransition: true,
              allowSlideNext: false,
              allowSlidePrev: false,
            },
          }}
          onSlideChangeTransitionEnd={swiper => {
            gsap.to(swiper.el, {
              duration: 0.3,
              scrollTo: { y: 0, offsetY: 10 },
            })
          }}
        // resizeObserver={false}
        // updateOnWindowResize={false}
        >
          <SwiperSlide key="contact">
            <div className="contact">
              <div className="innerCenter" dangerouslySetInnerHTML={{ __html: data.allDirectusAbout.nodes[0].contact }}></div>v1.0.1
            </div>
          </SwiperSlide>
          <SwiperSlide key="about">
            <div className="about">
              <div className="innerCenter" dangerouslySetInnerHTML={{ __html: data.allDirectusAbout.nodes[0].about }}></div>
            </div>
          </SwiperSlide>
          <SwiperSlide key="catalogue">
            <div className="catalogue">
              {/* <div className="innerCenter" dangerouslySetInnerHTML={{ __html: cataloguesContent }}></div> */}
              <div className="innerCenter">
                {
                  data.allDirectusAbout.nodes[0].files && data.allDirectusAbout.nodes[0].files.map( file => {
                    return (
                      <p key={Math.random()}>{file.file_name} <br /> 
                        <button 
                          className="like-link" 
                          onClick={() => { popupRef.current.openModal(file.file_iframe)}}
                        >Press to view</button>
                      </p>
                    )
                  })
                }
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </Modal>

      <Nav mod={modalRef1} />
      <Popup ref={popupRef}></Popup>

    </header>
      )}
    />
  )
}

export default Header