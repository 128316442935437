import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect
} from "react"

const Popup = forwardRef((props, ref) => {
  const [display, setDisplay] = useState(false)
  const [iframeContent, setIframeContent] = useState(false)

  useImperativeHandle(ref, () => {
    return {
      openModal: (a) => handleOpen(a),
      closeModal: () => handleClose(),
    }
  })

  useEffect(() => {
    const close = e => {
      if (e.keyCode === 27) {
        handleClose()
      }
    }
    window.addEventListener("keydown", close)
    return () => window.removeEventListener("keydown", close)
  }, [])

  const handleOpen = (a) => {
    setIframeContent(a);
    setDisplay(true)
  }

  const handleClose = ev => {
    setDisplay(false)
  }

  if (display) {
    return (
      <>
        <div className="pop-up">
          <div className="wrap" >
            <button class="btn modal-close" tabindex="0" onClick={handleClose}>close</button>
            <div className="iframe" dangerouslySetInnerHTML={{__html: iframeContent}}></div>
          </div>
        </div>
      </>
    )
  }

  return null
})

export default Popup
