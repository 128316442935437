import React, {useEffect, useRef} from "react"
import { Link } from "gatsby"
import MainContext from "../stores/mainContext"
import "scroll-behavior-polyfill"

const Nav = mod => {
  const { mainContext, dispatch } = React.useContext(MainContext)
  const clickHandler = (ev, par) => {
    ev.preventDefault()

    if(par !== "live"){
      live.current && live.current.classList.remove('active')
    }
    if(par !== "built"){
      built.current && built.current.classList.remove('active')
    }
    if(par !== "press"){
      press.current && press.current.classList.remove('active')
    }
    if(par !== "more"){
      more.current && more.current.classList.remove('active')
    }

    dispatch({ type: "SET_MODAL_OPENED", payload: false })
    if (mainContext.category === par) {
      dispatch({ type: "SET_CATEGORY", payload: null })
    } else {
      dispatch({ type: "SET_CATEGORY", payload: par })
    }
    mod.mod.current.closeModal()
  }

  const live = useRef()
  const built = useRef()
  const press = useRef()
  const more = useRef()

  // useEffect(() => {    
  //   const liveLink = live.current
  //   const builtLink = built.current
  //   const pressLink = press.current
  //   const moreLink = more.current
  //   if (liveLink) {
  //     liveLink.classList.remove('active')
  //   }
  //   if (builtLink) {
  //     builtLink.classList.remove('active')
  //   }
  //   if (pressLink) {
  //     pressLink.classList.remove('active')
  //   }
  //   if (moreLink) {
  //     moreLink.classList.remove('active')
  //   }
  // }, [mainContext.opened])

  return (
    <nav>
      <ul>
        {mainContext?.projects?.find(item => item.category === "live") && (
          <li>
            <Link
              to="/"
              ref={live}
              className={
                (mainContext.category === "live" && mainContext.opened !== true) ? "navLink active" : "navLink"
              }
              onClick={ev => clickHandler(ev, "live")}
            >
              live
            </Link>
          </li>
        )}
        {mainContext?.projects?.find(item => item.category === "built") && (
          <li>
            <Link
              to="/"
              ref={built}
              className={
                (mainContext.category === "built" && mainContext.opened !== true) ? "navLink active" : "navLink"
              }
              onClick={ev => clickHandler(ev, "built")}
            >
              built
            </Link>
          </li>
        )}
        {mainContext?.projects?.find(item => item.category === "press") && (
          <li>
            <Link
              to="/"
              ref={press}
              className={
                (mainContext.category === "press" && mainContext.opened !== true) ? "navLink active" : "navLink"
              }
              onClick={ev => clickHandler(ev, "press")}
            >
              press
            </Link>
          </li>
        )}
        {mainContext?.projects?.find(item => item.category === "more") && (
          <li>
            <Link
              to="/"
              ref={more}
              className={
                (mainContext.category === "more" && mainContext.opened !== true) ? "navLink active" : "navLink"
              }
              onClick={ev => clickHandler(ev, "more")}
            >
              more
            </Link>
          </li>
        )}
      </ul>
    </nav>
  )
}

export default Nav
