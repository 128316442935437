import * as React from "react"

import Header from "./header"
// import Nav from "./nav"
import mainReducer from "../stores/mainReducer"
import MainContext from "../stores/mainContext"

const Layout = ({ children }) => {
  const initialState = {
    searchText: "",
    searchResultCount: 0,
    category: null,
  }
  const [mainContext, dispatch] = React.useReducer(mainReducer, initialState)
  return (
    <MainContext.Provider value={{ mainContext, dispatch }}>
      <Header />

      <div className="layout">
        <main id="main">{children}</main>
        {/* <footer>© {new Date().getFullYear()} Groves Natcheva Architects</footer> */}
      </div>
    </MainContext.Provider>
  )
}

export default Layout
