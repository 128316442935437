const mainReducer = (state, action) => {
  switch (action.type) {
    case "SET_SEARCH_TEXT":
      return {
        ...state,
        searchText: action.payload,
      }
    case "SET_SEARCH_RESULT_COUNT":
      return {
        ...state,
        searchResultCount: action.payload,
      }
    case "SET_CATEGORY":
      return {
        ...state,
        category: action.payload,
      }
    case "SET_ACTIVE_PROJECT":
      return {
        ...state,
        activeProject: action.payload,
      }
    case "STOP_VIDEO":
      return {
        ...state,
        playing: action.payload,
      }
    case "SET_PROJECTS":
      return {
        ...state,
        projects: action.payload,
      }
    case "SET_SWIPER":
      return {
        ...state,
        vswiper: action.payload,
      }
    case "SET_MODAL_OPENED":
      return {
        ...state,
        opened: action.payload,
      }

    default:
      return state
  }
}
export default mainReducer
