import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useContext,
} from "react"
import { Link } from "gatsby"
import MainContext from "../stores/mainContext"

const Modal = forwardRef((props, ref) => {
  const { dispatch } = useContext(MainContext)
  const [display, setDisplay] = useState(false)

  useImperativeHandle(ref, () => {
    return {
      openModal: () => handleOpen(),
      closeModal: () => handleClose(),
    }
  })

  useEffect(() => {
    const close = e => {
      if (e.keyCode === 27) {
        handleClose()
      }
    }
    window.addEventListener("keydown", close)
    return () => window.removeEventListener("keydown", close)
  }, [])

  const handleOpen = () => {
    setDisplay(true)
  }

  const handleClose = ev => {
    if (ev) {
      dispatch({ type: "SET_CATEGORY", payload: null })
    }
    setDisplay(false)
    dispatch({ type: "SET_MODAL_OPENED", payload: false })
    // dispatch({ type: "STOP_VIDEO", payload: true });
  }

  if (display) {
    return (
      <>
        <div className="modal-backdrop"></div>
        <div className="modal-header">
          <h1>
            <Link to="/" onClick={e => handleClose(e)}>
              Groves Natcheva Architects
            </Link>
          </h1>
          <button
            className="btn modal-close"
            onClick={e => handleClose(e)}
            tabIndex="0"
          >
            close
          </button>
        </div>
        <div className="modal-container">{props.children}</div>
      </>
    )
  }

  return null
})

export default Modal
